import Vue from 'vue'
import { Component, Watch, Ref } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { ValidationObserver } from 'vee-validate'
import ErrorHandlerService from '../../services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrProfileView',
  computed: {
    ...mapState('security', ['currentUser', 'user', 'zapierKeys']),
    ...mapState('company', ['companies']),
    ...mapState('event', ['events'])
  }
})
export default class GtrProfileView extends GtrSuper {
  @Ref()
  readonly observerProfileForm!: InstanceType<typeof ValidationObserver>;

  zapierKeys!: any;

  data () {
    return {
      submitting: false,
      password: '',
      password_confirmation: '',
      name: '',
      email: '',
      profile_photo: null,
      profile_photo_to_save: '',
      access_level: '',
      company_uuid: '',
      company_name: '',
      two_factor_enabled: true,
      two_factor_method: '',
      two_factor_phone: null,
      event_uuid: null,
      _currentUser: null,
      _access_level: null,
      showEditProfileModal: false,
      enabled_yearly_roundup: 0,
      enabled_monthly_roundup: 0,
      enabled_weekly_roundup: 0,
      enabled_completed_registration_notification: 0,
      enabled_cancelled_registration_notification: 0,
      notifications: [
        {
          text: 'Yearly Roundup',
          description: 'Automated email triggered on the annual anniversary of when your account was activated with high level account KPIs for all events.',
          value: 'enabled_yearly_roundup'
        },
        {
          text: 'Monthly Roundup',
          description: 'Automated email triggered on the 1st of each month with high level account KPIs for all events.',
          value: 'enabled_monthly_roundup'
        },
        {
          text: 'Weekly Roundup',
          description: 'Automated email triggered weekly with high level account KPIs for all events.',
          value: 'enabled_weekly_roundup'
        },
        {
          text: 'New Completed Registration',
          description: 'Automated email triggered when a participant has completed registration.',
          value: 'enabled_completed_registration_notification'
        },
        {
          text: 'Registration Cancelled',
          description: 'Registration Cancelled: Automated email triggered when a participant has canceled registration.',
          value: 'enabled_cancelled_registration_notification'
        }
      ],
      showUpdateApiKeyDateTimeModal: false,
      updateApiKeyDateTime: null,
      showChangePasswordModal: false
    }
  }

  private handleEditProfileOpen () {
    this.$data.showEditProfileModal = true
  }

  private handleEditProfileClose () {
    this.$data.showEditProfileModal = false
    this.$data.profile_photo = null
  }

  get twoFactorOptions () {
    if (Vue.prototype.$hasRoles(['SUPER_ADMIN'])) {
      return [{ text: 'Yes', value: 1 }]
    }
    return [
      { text: 'No', value: 0 },
      { text: 'Yes', value: 1 }
    ]
  }

  get two_factor_clearable () {
    if (Vue.prototype.$hasRoles(['SUPER_ADMIN'])) {
      return false
    }
    return true
  }

  async mounted () {
    await this.fetchCurrentUser()
    await this.getApiKeys()
    if (this.zapierKeys.length === 0) {
      this.generateApiKey('my-api-key')
    }
  }

  @Watch('currentUser', { immediate: true })
  OnCurrentUserChange (newVal) {
    if (newVal) {
      this.$data._currentUser = newVal
      this.$data.name = newVal.name
      this.$data._access_level = this.getAccessLevelOfUser(newVal)
      this.$data.company_name = newVal.company ? newVal.company.name : ''
      this.$data.profile_photo_to_save = newVal.profile_photo
      this.$data.two_factor_method = newVal.two_factor_method
      this.$data.two_factor_phone = newVal.two_factor_phone
      this.$data.enabled_yearly_roundup = newVal.enabled_yearly_roundup
      this.$data.enabled_monthly_roundup = newVal.enabled_monthly_roundup
      this.$data.enabled_weekly_roundup = newVal.enabled_weekly_roundup
      this.$data.enabled_completed_registration_notification = newVal.enabled_completed_registration_notification
      this.$data.enabled_cancelled_registration_notification = newVal.enabled_cancelled_registration_notification
    }
  }

  get apiKey () {
    // eslint-disable-next-line
    if (this.zapierKeys!.length > 0) {
      // eslint-disable-next-line
      return this.zapierKeys![0]
    } else {
      return {
        api_key: null,
        uuid: null
      }
    }
  }

  async generateApiKey (name: string) {
    try {
      await this.$store.dispatch('security/generateZapierKey', {
        name
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  handleOpenApiKeyUpdateModal () {
    this.$data.showUpdateApiKeyDateTimeModal = true
  }

  handleCloseApiKeyUpdateModal () {
    this.$data.showUpdateApiKeyDateTimeModal = false
    this.$data.updateApiKeyDateTime = null
  }

  async updateApiKey () {
    try {
      const uuid = this.apiKey.uuid
      if (!uuid) {
        Container.get(ErrorHandlerService).error('Current API Key is missing')
        return
      }
      const expiration = this.convertFromEventTimezoneToUtc(this.$data.updateApiKeyDateTime)
      await this.$store.dispatch('security/updateZapierKey', {
        uuid,
        expiration
      })
      Container.get(Notification).success('API key successfully updated')
      this.handleCloseApiKeyUpdateModal()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async getApiKeys () {
    try {
      await this.$store.dispatch('security/getZapierKeys')
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  async fetchCurrentUser () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('security/getCurrentUser')
      this.$data._currentUser = response.data
      this.$data.name = response.data.name
      this.$data.email = response.data.email
      this.$data.profile_photo_to_save = response.data.profile_photo
      this.$data._access_level = this.getAccessLevelOfUser(response.data)
      this.$data.company_name = response.data.company ? response.data.company.name : ''
      this.$data.two_factor_enabled = response.data.two_factor_enabled
      this.$data.two_factor_method = response.data.two_factor_method
      this.$data.two_factor_phone = response.data.two_factor_phone
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async editProfile () {
    try {
      this.$data.submitting = true
      if (this.$data.profile_photo instanceof File) {
        this.$data.profile_photo_to_save = this.$data.profile_photo
      }
      const data: any = {
        name: this.$data.name,
        email: this.$data.email,
        profile_photo: this.$data.profile_photo_to_save,
        access_level: this.$data._currentUser.access_level,
        user_uuid: this.$data._currentUser.uuid,
        two_factor_enabled: this.$data.two_factor_enabled,
        two_factor_method: this.$data.two_factor_method,
        two_factor_phone: this.$data.two_factor_phone,
        enabled_yearly_roundup: this.$data.enabled_yearly_roundup,
        enabled_monthly_roundup: this.$data.enabled_monthly_roundup,
        enabled_weekly_roundup: this.$data.enabled_weekly_roundup,
        enabled_completed_registration_notification: this.$data.enabled_completed_registration_notification,
        enabled_cancelled_registration_notification: this.$data.enabled_cancelled_registration_notification
      }
      data.admin_base_url = window.location.origin
      if (this.$data._currentUser.access_level) {
        await this.$store.dispatch('security/updateUser', { user_uuid: this.$data._currentUser.uuid, data: data })
        await this.fetchCurrentUser()
        Container.get(Notification).success('User successfully updated.')
      }
    } catch (error) {
      this.$data.submitting = false
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  private profilePhotoFilesChange (file: File) {
    this.$data.profile_photo = file
  }

  goBack () {
    this.$router.go(-1)
  }

  handleOpenChangePasswordModal () {
    this.$data.showChangePasswordModal = true
  }

  handleCloseChangePasswordModal () {
    this.$data.showChangePasswordModal = false
    this.$data.password = ''
    this.$data.password_confirmation = ''
  }

  async changePassword () {
    try {
      if (this.$data.password && this.$data.password_confirmation) {
        const { password, password_confirmation } = this.$data
        const data = {
          password,
          password_confirmation
        }
        if (this.$data._currentUser.access_level) {
          await this.$store.dispatch('security/updateUser', { user_uuid: this.$data._currentUser.uuid, data: data })
          await this.fetchCurrentUser()
          this.handleCloseChangePasswordModal()
          Container.get(Notification).success('User successfully updated.')
        } else {
          Container.get(Notification).error('You do not have access to change your password.')
        }
      }
    } catch (e) {
      const message = Vue.prototype.translateError(e)
      Container.get(Notification).error(message || 'We were unable to change your password. Please try again.')
    }
  }

  onCopySuccess (event) {
    Container.get(Notification).success((event.trigger.dataset.copyField || 'Information') + ' successfully copied.')
  }

  onCopyError (event) {
    Container.get(Notification).error('There was an error copying the ' + (event?.trigger.dataset.copyField ? event.trigger.dataset.copyField.toLocaleLowerCase() : 'information') + '. Please refresh and try again.')
  }
}
